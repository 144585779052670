import React, { useState, useEffect, useReducer, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import defaultData from './defaultData'
import * as utils from './utils'

import {
  ACTION_RECORD_QUESTION_RESULT,
  ACTION_RECORD_ACTIVITY_TIME,
  ACTION_INCREMENT,
  ACTION_RESET,
  ACTION_RECORD_CONFIDENCE_LEVEL,
  OFFSET_ACT_INSTRUCTIONS,
  TIMER_SHORT_DELAY,
  TIMER_INSTRUCTIONS,
  ACTION_INCREMENT_PHASE,
  ACTION_INCREMENT_QUESTION,
  ACTION_GOTO_PHASE,
  QUESTIONS_KEY,
  RC_DIGIT_SIGN,
  ACTION_SET_PHASE_AND_QUESTION,
  ACTION_SET_RESULTS,
} from './constants'
import {
  simpleReducer,
  dataReducer,
  resultsReducer,
  locationReducer,
  errorReducer,
} from './reducers'

export const StoreContext = React.createContext()

const params = new URLSearchParams(document.location.search)
const path = document.location.pathname.split('/')
// Assumption: Old urls are redirected to a new URL path.
// returns an array ["", "test", "ORGANIZATION_SLUG", ""]
let organization = path[2]
const subPath = path[3]
let sequenceId = null
let selfServeSlug = null
if (path.length === 6 && path[3].toLowerCase() === 'seq') {
  // Weird array-destructuring for linter
  sequenceId = path[4] || null
}
// Check for new self-serve path length
// "/self-serve/<slug>/
if (path[1].toLowerCase() === 'self-serve') {
  selfServeSlug = path[2] || null
  // Organization is not path[2] in this instance.
  // The selfServeSlug is attached to an organization
  organization = null
} else if (path[2].toLowerCase() === 'self-serve') {
  selfServeSlug = path[3] || null
  organization = null
}
const Store = ({ children, updateErrorRedirect, updateContactMessage }) => {
  const [errorState, setErrorState] = useReducer(errorReducer, {})
  const [startTime] = useState(Date.now())
  const [language, setLanguage] = useState(params.get('lang') || 'en')
  const [assignmentId] = useState(params.get('a'))
  const [assignment, setAssignment] = useState(null)
  const [testVariationId, setTestVariationId] = useState(params.get('v'))
  const [userProvidedID, setUserProvidedID] = useState(params.get('id'))
  const [displayFeedbackQP, setDisplayFeedbackQP] = useState(params.get('df'))
  const [token, setToken] = useState(params.get('token'))
  const [redirectErrorHref, setRedirectErrorHref] = useState(null)
  const [redirectCompleteHref, setRedirectCompleteHref] = useState(null)
  const [tokenChecked, setTokenChecked] = useState(false)
  const [dsWeight] = useState(params.get('ds_weight'))
  const [giveSurvey] = useState(params.has('survey'))
  const [isLoading, setIsLoading] = useState(true)
  const [isPractice, setIsPractice] = useState(null)
  const [hasAudioFeedback, setHasAudioFeedback] = useState(false)
  const [hasVisualFeedback, setHasVisualFeedback] = useState(false)
  const [stopTimer, setStopTimer] = useState(false)
  const [showBadge, setShowBadge] = useState(false)
  const [displayEndScreen, setDisplayEndScreen] = useState(false)
  const [questionStartTime, setQuestionStartTime] = useState(null)
  const [userResponse, setUserResponse] = useState(null)
  const [followupResponse, setFollowupResponse] = useState(null)
  const [hasFollowUpQuestion, setHasFollowUpQuestion] = useState(false)
  const [hasTextInput, setHasTextInput] = useState(false)
  const [hasFollowupTextInput, setHasFollowupTextInput] = useState(false)
  const [textInput, setTextInput] = useState(null)
  const [followupTextInput, setFollowupTextInput] = useState(null)
  const [percentTimeSpent, setPercentTimeSpent] = useState(null)
  const [sequenceRetryCount, setSequenceRetryCount] = useState(0)
  const [location, dispatchLocation] = useReducer(locationReducer, {
    phase: 0,
    question: 0,
  })
  const [attemptNumber, dispatchAttemptNumber] = useReducer(simpleReducer, 0)
  const [retryCount, dispatchRetryCount] = useReducer(simpleReducer, 0)
  const [shouldAskUserReady, setShouldAskUserReady] = useState(false)
  const [data, dispatchData] = useReducer(dataReducer, defaultData)
  const [results, dispatchResults] = useReducer(resultsReducer, null)
  const [questionCount, setQuestionCount] = useState(null)
  const [didAnswerCorrectly, setDidAnswerCorrectly] = useState(null)
  const [activityStartTime, setActivityStartTime] = useState(null)
  const [idAttemptedRecently, setIdAttemptedRecently] = useState({
    has_recent_submission: null,
  })
  const [afterFeedback, setAfterFeedback] = useState(false)
  const [userConsented, setUserConsented] = useState(null)
  const [requiresConsent, setRequiresConsent] = useState(false)
  const [consentMarkdown, setConsentMarkdown] = useState(null)
  const [skipSurveyQuestions, setSkipSurveyQuestions] = useState([])
  const [callNextQuestion, setCallNextQuestion] = useState(false)
  const devMode = process.env.NODE_ENV === 'development'

  const getString = useCallback(
    (id) => {
      return data.strings[language][id]
    },
    [data.strings, language]
  )

  // Development only, allows jumping to a phase
  useEffect(() => {
    if (devMode) {
      window.addEventListener('keydown', (event) => {
        if (event.key === '`') {
          const goTo = parseInt(window.prompt('Jump to: ', 0), 10)
          dispatchLocation({
            type: ACTION_GOTO_PHASE,
            payload: { phase: goTo },
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fetch the assignment information
  // Error States: SE2
  useEffect(() => {
    if (assignmentId !== null) {
      fetch(`/test/${organization}/api/v2/json/assignment/${assignmentId}/`, {
        method: 'GET',
      })
        .then((response) => response.json())
        // eslint-disable-next-line no-shadow
        .then((assignment) => {
          setAssignment(assignment)
          const { currentPhaseNumber, currentQuestionNumber } = assignment
          if (currentPhaseNumber !== 0 && currentQuestionNumber !== 0) {
            // Can't dispatch the location yet since the test information may not be loaded.
            // Set the result set (submit endpoint is additive)
            dispatchResults({
              type: ACTION_SET_RESULTS,
              payload: assignment.results,
            })
          }
          setLanguage(assignment.language)
          updateContactMessage(getString('contactAdmin'))
          setIsLoading(false)
        })
        .catch((err) => {
          Sentry.captureException(err)
          const newErr = new Error(getString('fetchFailedError'))
          // There was an error in the request, errorBoundary will set the unique-id. Set the errorCode so we know where the failure occurred
          newErr.errorCode = 'SE2'
          setErrorState({ type: 'error', error: newErr })
        })
    }
  }, [assignmentId, getString, updateContactMessage])

  // Throw error if we do not have valid URL setup
  useEffect(() => {
    if (
      assignmentId === null &&
      testVariationId === null &&
      sequenceId == null &&
      selfServeSlug == null
    ) {
      const newErr = new Error(data.strings.en.invalidLink)
      newErr.errorCode = 'LI2'
      setErrorState({ type: 'error', error: newErr })
    }
  }, [assignmentId, testVariationId, data.strings.en.invalidLink])

  // If we have a token, validate it and retrieve the redirects
  // Error States: TK1, TK2
  useEffect(() => {
    // Don't run if we don't have a token, or we have already checked the token
    if (!token || tokenChecked) {
      return
    }
    const formData = new FormData()
    formData.append('token', token)

    fetch(`/api/v3/organization/${organization}/check-token/`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        setTokenChecked(true)
        if (json.error) {
          const err = new Error(getString('tokenError1'))
          err.errorCode = json.error_code
          err.uniqueID = json.unique_id
          setErrorState({ type: 'error', error: err })
        } else {
          setRedirectErrorHref(json.redirect_error)
          // Pass our error redirect to the ErrorBoundary
          updateErrorRedirect(json.redirect_error)
          setRedirectCompleteHref(json.redirect_success)
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
        const newErr = new Error(getString('fetchFailedError'))
        // There was an error in the request, errorBoundary will set the unique-id. Set the errorCode so we know where the failure occurred
        newErr.errorCode = 'SE3'
        setErrorState({ type: 'error', error: newErr })
      })
  }, [
    token,
    tokenChecked,
    redirectCompleteHref,
    redirectErrorHref,
    getString,
    updateErrorRedirect,
  ])

  // Query API with ID to see if there was a recent attempt
  // Error states: TA1, SE4
  useEffect(() => {
    // Don't run the check-id if an ID is provided
    // Don't run the check if we are doing self-serve assignment sequence
    // Don't run the check if we are doing self-serve through saved URL
    if (!userProvidedID || sequenceId || selfServeSlug) {
      return
    }

    const formData = new FormData()
    formData.append('user_provided_id', userProvidedID)
    if (testVariationId !== null && assignmentId === null) {
      formData.append('test_variation_id', testVariationId)
    }
    if (assignmentId !== null) {
      formData.append('assignment_id', assignmentId)
    }
    fetch(`/test/${organization}/api/v2/check-id/`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        if ('error' in json) {
          setLanguage(json.language)
          updateContactMessage(getString('contactAdmin'))
          const err = new Error(getString('failedLoadTest'))
          err.errorCode = json.error_code
          err.uniqueID = json.unique_id
          setErrorState({ type: 'error', error: err })
        }
        setRequiresConsent(json.requires_consent)
        setConsentMarkdown(json.consent_markdown)
        setIdAttemptedRecently(json)
      })
      .catch((err) => {
        // Capture the original error
        Sentry.captureException(err)
        const newErr = new Error(getString('fetchFailedError'))
        // There was an error in the request, errorBoundary will set the unique-id. Set the errorCode so we know where the failure occurred
        newErr.errorCode = 'SE4'
        setErrorState({ type: 'error', error: newErr })
      })
  }, [
    userProvidedID,
    assignmentId,
    testVariationId,
    setRequiresConsent,
    setConsentMarkdown,
    setIdAttemptedRecently,
    errorState,
    getString,
    updateContactMessage,
  ])

  // Get the self-serve data from sequence_id
  // TODO Remove this hook after the saved_url is implemented and used
  useEffect(() => {
    if (sequenceRetryCount >= 2) {
      const newErr = Error(
        'Something went wrong while retrieving the sequence.'
      )
      setErrorState({ type: 'error', error: newErr })
    }
    if (
      sequenceId !== null &&
      userProvidedID !== null &&
      requiresConsent === false &&
      isLoading === true &&
      sequenceRetryCount < 2
    ) {
      const formData = new FormData()
      formData.append('user_provided_id', userProvidedID)
      fetch(`/test/${organization}/api/v2/seq/${sequenceId}/self-serve/`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          return response.json()
        })
        .then((json) => {
          setRequiresConsent(json.requires_consent)
          setConsentMarkdown(json.consent_markdown)
          setLanguage(json.participant_language)
          updateContactMessage(getString('contactAdmin'))
          setIsLoading(false)
        })
        .catch((err) => {
          Sentry.withScope((scope) => {
            scope.setContext({
              requires_consent: requiresConsent,
              sequence_retry_count: sequenceRetryCount,
            })
            Sentry.captureException(err)
          })
          // We automatically retry this check if it fails
          setSequenceRetryCount(sequenceRetryCount + 1)
        })
    }
  }, [
    userProvidedID,
    setRequiresConsent,
    setConsentMarkdown,
    requiresConsent,
    isLoading,
    sequenceRetryCount,
    setSequenceRetryCount,
    getString,
    updateContactMessage,
  ])

  // Get the self-serve consent form data from saved_url_slug
  useEffect(() => {
    if (
      selfServeSlug !== null &&
      userProvidedID !== null &&
      requiresConsent === false &&
      isLoading === true
    ) {
      const formData = new FormData()
      formData.append('user_provided_id', userProvidedID)
      fetch(`/api/v4/self-serve/${selfServeSlug}/`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          return response.json()
        })
        .then((json) => {
          setRequiresConsent(json.requires_consent)
          setConsentMarkdown(json.consent_markdown)
          setLanguage(json.participant_language)
          updateContactMessage(getString('contactAdmin'))
          setIsLoading(false)
        })
        .catch((err) => {
          Sentry.withScope((scope) => {
            scope.setContext({
              requires_consent: requiresConsent,
            })
            Sentry.captureException(err)
            const newErr = new Error(getString('fetchFailedError'))
            // There was an error in the request, errorBoundary will set the unique-id. Set the errorCode so we know where the failure occurred
            newErr.errorCode = 'SE5'
            setErrorState({ type: 'error', error: newErr })
          })
        })
    }
  }, [
    userProvidedID,
    setRequiresConsent,
    setConsentMarkdown,
    requiresConsent,
    isLoading,
    getString,
    updateContactMessage,
  ])

  // Convenience
  const getPhase = useCallback(
    (isPostFeedbackPhase = false) => {
      return isPostFeedbackPhase
        ? data.postFeedbackPhases[location.phase]
        : data.phases[location.phase]
    },
    [data.phases, data.postFeedbackPhases, location]
  )
  const getQuestion = (isPostFeedbackPhase = false) => {
    const phase = getPhase(isPostFeedbackPhase)
    return phase[QUESTIONS_KEY][location.question - OFFSET_ACT_INSTRUCTIONS]
  }

  const getTimer = useCallback(
    (timer) => {
      // FIXME this needs to adapt to global instructions being any phase

      switch (timer) {
        case TIMER_SHORT_DELAY:
          return 0.65
        case TIMER_INSTRUCTIONS: {
          const globalInstructionsSeconds = 5
          if (globalInstructionsSeconds) {
            return devMode ? 1 : globalInstructionsSeconds
          }
          // Fallback
          return devMode ? 1 : 5
        }
        case undefined: {
          const seconds = getPhase(afterFeedback).timer_seconds
          if (seconds === null) {
            return false
          }
          if (devMode) {
            return seconds * 0.3
          }
          return seconds
        }
        default:
          // eslint-disable-next-line no-case-declarations
          const err = new Error(getString('fetchFailedError'))
          err.errorCode = 'TM1'
          setErrorState({
            type: 'error',
            error: err,
          })
          // This throw is useless due to setErrorState, but it suppresses the linter needing a return
          throw new Error('Something went wrong with the timer')
      }
    },
    [afterFeedback, devMode, getPhase, getString]
  )

  const startPostFeedbackPhases = () => {
    // Reset the location information since we are accessing a different array.
    const resetLocationAndUpdateAfterFeedback = () => {
      dispatchLocation({ type: ACTION_RESET })
      setAfterFeedback(true)
    }
    setStopTimer(true)
    setTimeout(
      resetLocationAndUpdateAfterFeedback,
      getTimer(TIMER_SHORT_DELAY) * 1000
    )
  }

  // Error States: LI1, SE1
  const fetchTaskPhases = async () => {
    if (testVariationId === null && assignment.testVariationId === null) {
      const newErr = new Error(getString('testAccessError2'))
      // The testVariation is not available to form the URL for the request, review the useEffect for fetching the assignment, or the URL param: v
      // ErrorBoundary.jsx will set the unique-id, set the errorCode for consistent grouping
      newErr.errorCode = 'LI1'
      setErrorState({ type: 'error', error: newErr })
    }

    const id = testVariationId || assignment.testVariationId

    try {
      const url = `/api/v1/json/organization/${organization}/tests/${id}/`
      const response = await fetch(url)
      const { phases, postFeedbackPhases, formatVersion, testVariation } =
        await response.json()
      setTestVariationId(testVariation.testVariationId)

      if (
        testVariation.language !== null &&
        testVariation.language !== language
      ) {
        setLanguage(testVariation.language)
        updateContactMessage(getString('contactAdmin'))
      }
      await dispatchData({
        payload: {
          phases,
          postFeedbackPhases,
          formatVersion,
          testVariation,
        },
      })
      if (
        assignment &&
        assignment.currentPhaseNumber !== undefined &&
        assignment.currentQuestionNumber !== undefined
      ) {
        await dispatchLocation({
          type: ACTION_SET_PHASE_AND_QUESTION,
          payload: {
            phase: assignment.currentPhaseNumber,
            question: assignment.currentQuestionNumber,
          },
        })
      }
    } catch (err) {
      // There was an error in the request, errorBoundary will set the unique-id. Set the errorCode so we know where the failure occurred
      Sentry.captureException(err)
      const newErr = new Error(getString('fetchFailedError'))
      newErr.errorCode = 'SE1'
      setErrorState({ type: 'error', error: newErr })
    } finally {
      setIsLoading(false)
    }
  }

  // Once data is loaded, preload all images
  useEffect(() => {
    if (
      isLoading === false &&
      idAttemptedRecently.has_recent_submission === false
    ) {
      const { phases } = data
      let millisecondDelay = 3000
      Object.values(phases).forEach((phase) => {
        if (phase[QUESTIONS_KEY] && phase[QUESTIONS_KEY].length) {
          setTimeout(() => {
            utils.preloadImages(phase[QUESTIONS_KEY])
          }, millisecondDelay)
          // If the phase is a practice/instruction phase, shorten the delay increase
          // Practice phases generally have fewer questions to load, so shortening the delay will lead to less chance of entering a phase before images are loaded
          // Practice phases will start loading the next phase two seconds after
          // Non-Practice phases will start loading the next phase 5 seconds after.
          if (phase.is_practice) {
            millisecondDelay += 2000
          } else {
            millisecondDelay += 5000
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data])

  const incrementPhase = () => {
    dispatchLocation({ type: ACTION_INCREMENT_PHASE })
  }

  // Populate phases from API
  useEffect(() => {
    // Only fetch if a test variation id has been provided in URL params
    // and the provided user id has not attempted recently
    if (isLoading && idAttemptedRecently.has_recent_submission === false) {
      fetchTaskPhases()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, idAttemptedRecently, organization])

  // Set activity data when the activity changes
  useEffect(() => {
    const phase = getPhase(afterFeedback)
    setIsPractice(phase.is_practice)
    const questionSet = phase[QUESTIONS_KEY]
    setQuestionCount(
      questionSet === undefined ? null : phase[QUESTIONS_KEY].length
    )
    setHasAudioFeedback(phase.has_audio_feedback)
    setHasVisualFeedback(phase.has_visual_feedback)
    setShowBadge(phase.show_badge)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.phase, afterFeedback])

  const recordConfidenceLevel = (wasConfident) => {
    dispatchResults({
      afterFeedback,
      type: ACTION_RECORD_CONFIDENCE_LEVEL,
      payload: {
        onPhase: location.phase,
        onQuestion: location.question,
        question: getQuestion(afterFeedback),
        wasConfident,
      },
    })
  }
  const addQuestion = (newQuestion) => {
    // Splice in the new item to the current position
    if (afterFeedback) {
      data.postFeedbackPhases[location.phase][QUESTIONS_KEY].splice(
        location.question,
        0,
        newQuestion
      )
    } else {
      data.phases[location.phase][QUESTIONS_KEY].splice(
        location.question,
        0,
        newQuestion
      )
    }
    // Update our question count
    setQuestionCount((prevCount) => prevCount + 1)
  }

  const recordQuestionResult = (
    timeTaken,
    response,
    hasExtraInput = false,
    extraTextInput = null,
    hasFollowupQuestion = false,
    followupResp = null,
    hasFollowupText = false,
    followupText = null
  ) => {
    dispatchResults({
      type: ACTION_RECORD_QUESTION_RESULT,
      afterFeedback,
      payload: {
        onPhase: location.phase,
        onQuestion: location.question,
        phase: getPhase(afterFeedback),
        question: getQuestion(afterFeedback),
        attemptNumber,
        timeTaken,
        didAnswerCorrectly,
        response,
        hasExtraInput,
        extraTextInput,
        hasFollowupQuestion,
        followupResp,
        hasFollowupText,
        followupText,
      },
    })
    setFollowupResponse(null)
    setFollowupTextInput(null)
  }

  // Reset question fields on change
  useEffect(() => {
    dispatchAttemptNumber(ACTION_RESET)
    dispatchRetryCount(ACTION_RESET)
    setDidAnswerCorrectly(null)
    setUserResponse(null)
    setHasTextInput(false)
    setTextInput(null)
    setStopTimer(false)
  }, [location.question])

  // Record reactionTime from questionStartTime to didAnswerCorrectly
  useEffect(() => {
    if (attemptNumber !== 0) {
      const timeTaken =
        Math.round((performance.now() - questionStartTime) / 10) / 100
      recordQuestionResult(
        timeTaken,
        userResponse,
        hasTextInput,
        textInput,
        hasFollowUpQuestion,
        followupResponse,
        hasFollowupTextInput,
        followupTextInput
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptNumber])

  // Record activity time taken
  useEffect(() => {
    // Do not fire on init because of comment below
    if (location.phase) {
      const timeTaken =
        Math.round((performance.now() - activityStartTime) / 10) / 100
      // Record the name and number of previous phase since this effect occurs
      // after the phase has changed
      const phase = data.phases[location.phase - 1]
      dispatchResults({
        afterFeedback,
        type: ACTION_RECORD_ACTIVITY_TIME,
        payload: {
          onPhase: location.phase - 1,
          phase,
          timeTaken,
        },
      })
    }
    setActivityStartTime(performance.now())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.phase])

  const generateSubmission = useCallback(
    ({ isComplete }) => {
      const phase = getPhase(afterFeedback)

      const shouldIncrementPhase =
        phase[QUESTIONS_KEY] !== undefined &&
        location.question + 1 > phase[QUESTIONS_KEY].length
      const currentPhase = shouldIncrementPhase
        ? location.phase + 1
        : location.phase
      const currentQuestion = shouldIncrementPhase ? 0 : location.question + 1
      return {
        id: userProvidedID,
        startTime,
        results,
        currentPhaseNumber: currentPhase,
        currentQuestionNumber: currentQuestion,
        complete: isComplete,
        test: data.testVariation,
        testId: testVariationId,
        assignmentKey: assignment ? assignment.key : null,
        organizationSlug: organization,
      }
    },
    [
      assignment,
      data.testVariation,
      results,
      startTime,
      testVariationId,
      userProvidedID,
      location,
      afterFeedback,
      getPhase,
    ]
  )

  const nextQuestion = useCallback(() => {
    if (questionCount === null) {
      dispatchLocation({ type: ACTION_INCREMENT_PHASE })
    } else if (location.question < questionCount) {
      if (!isPractice) {
        utils.submit(generateSubmission({ isComplete: false }))
      }
      dispatchLocation({ type: ACTION_INCREMENT_QUESTION })
    } else {
      if (!isPractice) {
        utils.submit(generateSubmission({ isComplete: false }))
      }
      const phase = getPhase(afterFeedback)
      if (phase.react_component === RC_DIGIT_SIGN) {
        setDisplayEndScreen(true)
        // Bypass the final dispatchLocation since we need to display the end screen.
        // We will increment the phase using nextPhase()
        return
      }
      dispatchLocation({ type: ACTION_INCREMENT_PHASE })
    }
  }, [
    afterFeedback,
    generateSubmission,
    getPhase,
    isPractice,
    location.question,
    questionCount,
  ])

  // Currently only used in digitSign questions
  const nextPhase = () => {
    utils.submit(generateSubmission({ isComplete: false }))
    // Reset so that we don't have issues with multiple digitSign phases.
    setDisplayEndScreen(false)
    setPercentTimeSpent(0)
    dispatchLocation({ type: ACTION_INCREMENT_PHASE })
  }

  const startAssignment = () => {
    setIsLoading(true)
    setUserProvidedID(assignment.participantId)
    setTestVariationId(assignment.testVariationId)
    fetchTaskPhases()
  }

  const nextQuestionWithShortDelay = useCallback(() => {
    setStopTimer(true)
    setTimeout(nextQuestion, getTimer(TIMER_SHORT_DELAY) * 1000)
  }, [getTimer, nextQuestion])

  // This hook allows us to wait for a rerender before moving on to the next question
  // We need to wait for the rerender in MultiSelect.js so that questionCount is updated from StoreContext.addQuestion()
  // if there is a followup question on the last item of our Survey. Otherwise the followup-question will be skipped.
  useEffect(() => {
    if (callNextQuestion) {
      setCallNextQuestion(false)
      nextQuestionWithShortDelay()
    }
  }, [callNextQuestion, nextQuestionWithShortDelay, questionCount])

  const onTimeExpired = () => {
    if (didAnswerCorrectly === null) {
      recordQuestionResult(
        0,
        userResponse,
        hasTextInput,
        textInput,
        hasFollowUpQuestion,
        followupResponse,
        hasFollowupTextInput,
        followupTextInput
      )
    }
    nextQuestion()
  }

  const playSFX = (wasCorrect) => {
    if (wasCorrect) {
      window.branchGlobals.correctSFX.currentTime = 0
      window.branchGlobals.correctSFX
        .play()
        .then(() => {
          // Audio Playing Correctly
        })
        .catch((error) => {
          // Failed to play the audio, log it to sentry.
          Sentry.captureException(error)
        })
    } else {
      window.branchGlobals.incorrectSFX.currentTime = 0
      window.branchGlobals.incorrectSFX.play()
    }
  }

  // Play incorrect SFX when choosing an incorrect answer
  useEffect(() => {
    if (location.phase > 3) {
      if (hasAudioFeedback) {
        if (didAnswerCorrectly === true) {
          playSFX(true)
        }
        // check false specifically to avoid cases where didAnswerCorrectly is null
        if (didAnswerCorrectly === false) {
          playSFX(false)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptNumber])

  useEffect(() => {
    if (retryCount === 1 && shouldAskUserReady === false) {
      recordQuestionResult(
        0,
        userResponse,
        hasTextInput,
        textInput,
        hasFollowUpQuestion,
        followupResponse,
        hasFollowupTextInput,
        followupTextInput
      )
      nextQuestion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAskUserReady, retryCount])

  const incrementAttemptNumber = () => dispatchAttemptNumber(ACTION_INCREMENT)

  const askUserAreYouThere = () => {
    dispatchRetryCount(ACTION_INCREMENT)
    setShouldAskUserReady(true)
  }

  return (
    <StoreContext.Provider
      value={{
        activityStartTime,
        askUserAreYouThere,
        assignment,
        assignmentId,
        attemptNumber,
        consentMarkdown,
        data,
        didAnswerCorrectly,
        dispatchAttemptNumber,
        dispatchLocation,
        displayEndScreen,
        dsWeight,
        generateSubmission,
        getPhase,
        getQuestion,
        getString,
        getTimer,
        giveSurvey,
        hasAudioFeedback,
        hasTextInput,
        hasVisualFeedback,
        idAttemptedRecently,
        incrementAttemptNumber,
        incrementPhase,
        isLoading,
        isPractice,
        language,
        location,
        nextPhase,
        nextQuestion,
        nextQuestionWithShortDelay,
        onTimeExpired,
        organization,
        playSFX,
        questionCount,
        questionStartTime,
        recordConfidenceLevel,
        recordQuestionResult,
        requiresConsent,
        results,
        selfServeSlug,
        setActivityStartTime,
        setConsentMarkdown,
        setDidAnswerCorrectly,
        setHasTextInput,
        setHasFollowUpQuestion,
        setQuestionStartTime,
        setShouldAskUserReady,
        setTextInput,
        setUserConsented,
        setUserProvidedID,
        setUserResponse,
        setFollowupResponse,
        setRequiresConsent,
        setHasFollowupTextInput,
        setFollowupTextInput,
        startAssignment,
        shouldAskUserReady,
        showBadge,
        startTime,
        stopTimer,
        subPath,
        token,
        tokenChecked,
        redirectCompleteHref,
        redirectErrorHref,
        testVariationId,
        textInput,
        userConsented,
        userProvidedID,
        userResponse,
        followupResponse,
        hasFollowupTextInput,
        followupTextInput,
        hasFollowUpQuestion,
        percentTimeSpent,
        setPercentTimeSpent,
        sequenceId,
        setIsLoading,
        displayFeedbackQP,
        setDisplayFeedbackQP,
        setToken,
        skipSurveyQuestions,
        setSkipSurveyQuestions,
        afterFeedback,
        setAfterFeedback,
        startPostFeedbackPhases,
        addQuestion,
        errorState,
        setErrorState,
        callNextQuestion,
        setCallNextQuestion,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

Store.propTypes = { children: PropTypes.node.isRequired }

export default Store
